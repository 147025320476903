import { devLog, devLogError } from '../log'
import { iframeMessage } from '../utils'
import { post } from 'aws-amplify/api'

export const API = {
  findByFace: async ({
    userId,
    image,
    type,
  }: {
    userId: string
    image: string
    type: string
  }) => {
    const request = post({
      apiName: 'findByFace',
      path: '/findByFace',
      options: {
        body: {
          type,
          userId,
          image,
        },
      },
    })
    try {
      const { body } = await request.response
      const { data, status, errorCategory } = (await body.json()) as { data?: string, status?: string, errorCategory?: string }
      const resultType = type === 'register' ? 'registeredId' : 'driverId'
      const msg = status === 'success' ? { type: resultType, data } : { type: 'error', data, errorCategory }
      devLog(msg)
      iframeMessage(msg)
    } catch (error: any) {
      iframeMessage({
        type: 'error',
        data: JSON.parse(error?.response?.body ?? '{}').error ?? 'Unexpected Error',
        errorCategory:  JSON.parse(error?.response?.body ?? '{}').errorCategory ?? 'Unexpected Error'
      })
      devLogError('testing amplify lib again', request)
      throw error
    }
  },
}
