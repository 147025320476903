import { devLogError } from '../../log'

type StartCameraArgs = {
  videoEl: HTMLVideoElement
  onStream?: (stream: MediaStream) => void | Promise<void>
  onCameraStart?: () => void | Promise<void>
}

export async function startCamera({
  videoEl,
  onStream,
  onCameraStart,
}: StartCameraArgs) {
  if (!videoEl.paused) return

  await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(async (stream) => {
      if (!videoEl) return

      onStream?.(stream)
      videoEl.srcObject = stream

      requestAnimationFrame(async () => {
        if (!videoEl) return
        await videoEl
          .play()
          .then(() => onCameraStart?.())
          .catch((err) => {
            devLogError(err)
            startCamera({ videoEl, onStream, onCameraStart })
          })
      })
    })
    .catch((error) => devLogError('Error accessing camera:', error))
}

export async function stopCamera(stream?: MediaStream) {
  if (stream) stream.getTracks().forEach((track) => track.stop())
}
